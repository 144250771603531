import React, { useState, useEffect } from "react";
import {
  CloudUpload as CloudUploadIcon,
  Delete as DeleteIcon,
  PictureAsPdf as PdfIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";

const Areasubida = ({ fileURL, onChange, error, setError }) => {
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (fileURL) {
      if (typeof fileURL === 'string' && fileURL.startsWith('http')) {
        setFile({ url: fileURL, isExternal: true, name: "Ficha técnica" });
      } else {
        setFile({ data: fileURL, isExternal: false, name: "Ficha técnica" });
      }
    }
  }, [fileURL]);

  const handleAddFile = (event) => {
    const selectedFile = event.target.files[0];
    const maxSize = 1 * 1024 * 1024; // 1MB en bytes
    const allowedTypes = ["application/pdf"];

    if (selectedFile && allowedTypes) {
      if (selectedFile.size > maxSize) {
        const errorMessage = `El archivo ${selectedFile.name} supera el tamaño máximo de 1MB.`;
        setError(errorMessage);
        return;
      }
      if (!allowedTypes.includes(selectedFile.type)) {
        const errorMessage = `El archivo ${selectedFile.name} no es un PDF válido.`;
        setError(errorMessage);
        return;
      }
      setError(null);

      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result;
        setFile({ data: base64, name: selectedFile.name, isExternal: false });
        onChange(base64);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    onChange("");
  };

  const handleViewFile = () => {
    if (file) {
      window.open(file.isExternal ? file.url : file.data, "_blank");
    }
  };

  return (
    <div className="max-w-2xl p-4">
      <div className="text-center">
      <Typography variant="h5" className="font-semibold mb-2" style={{fontSize:"20px"}}>
        Ficha tecnica del producto
      </Typography>
      <Typography variant="body2" className="text-gray-600 mb-2" style={{fontSize:"13px"}}>
        Adjunte la ficha tecnica del producto
      </Typography>
      <Typography variant="body2" className="text-gray-600 mb-6" style={{fontSize:"13px"}}>
        Tamaño máximo del archivo: 1 MB.
      </Typography>
      </div>

      <div className="relative mt-4">
        <input
          accept="application/pdf"
          className="hidden"
          id="raised-button-file"
          type="file"
          onChange={handleAddFile}
        />
        
        {!file ? (
          <label
            htmlFor="raised-button-file"
            className="cursor-pointer block p-8 border-2 border-dashed border-gray-300 rounded-lg bg-gray-50 hover:bg-gray-100 transition-colors mb-2"
          >
            <div className="flex items-center justify-center">
              <div className="p-2 rounded" style={{background:"#7dca6b"}}>
                <CloudUploadIcon className="text-white" />
              </div>
              <span className="ml-2 text-gray-600">Subir ficha técnica</span>
            </div>
          </label>
        ) : (
          <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center">
              <div className="bg-red-500 p-2 rounded">
                <PdfIcon className="text-white" />
              </div>
              <div className="ml-3 text-left">
                <Typography variant="body2" className="font-medium">
                  {file.name}
                </Typography>
                <Typography variant="caption" className="text-gray-500">
                  PDF
                </Typography>
              </div>
            </div>
            <div className="flex items-center space-x-2">
              <IconButton
                onClick={handleViewFile}
                className="p-1.5 hover:bg-gray-200 rounded-full transition-colors"
                size="small"
              >
                <VisibilityIcon className="text-gray-500" />
              </IconButton>
              <IconButton
                onClick={handleRemoveFile}
                className="p-1.5 hover:bg-gray-200 rounded-full transition-colors"
                size="small"
              >
                <DeleteIcon className="text-gray-500" />
              </IconButton>
            </div>
          </div>
        )}
        
        {error && (
          <Typography
            variant="body2"
            className="text-red-500 mt-2"
          >
            {error}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default Areasubida;