
export default async function editarElemento(url, token, producto) {
    try {
      const response = await fetch(url, {
        method: 'PUT', // Cambiamos el método a PUT
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            producto: producto,
            token: token,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      return { data, loading: false, error: null };
    } catch (error) {
      return { data: null, loading: false, error };
    }
  }