// CustomCheckboxGroup.js
import React from 'react';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, FormGroup } from '@mui/material';

const CustomCheckboxGroup = ({ options, onChange, errors,sx}) => {
  return (
    <FormControl component="fieldset" error={!!errors}>
      <FormGroup>
      {errors && <FormHelperText style={{ color: "#d33333" }}>{errors}</FormHelperText>}
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            control={
              <Checkbox
                checked={option.checked}
                onChange={(e) => onChange(option.value, e.target.checked)}
                color="primary"
                sx={{
                  '&.Mui-checked': {
                    color: '#7dca6b',
                  },
                  ...sx, // Aplicar estilos personalizados
                }}
              />
            }
            label={option.label}
          />
        ))}
      </FormGroup>
     
    </FormControl>
  );
};

export default CustomCheckboxGroup;
