import React, { useState } from "react";
import { Button, Box, Typography, Container, IconButton } from "@mui/material";
import {
  Delete as DeleteIcon,
  AddAPhoto as AddAPhotoIcon,
} from "@mui/icons-material";

const SubirFotos = ({ photoURLs, onChange, error, setError }) => {
  const [localError, setLocalError] = useState("");

  // Asegúrate de que photoURLs sea un array
  const validPhotoURLs = Array.isArray(photoURLs) ? photoURLs : [];

  const handleAddPhoto = (event) => {
    const file = event.target.files[0];
    const maxSize = 2 * 1024 * 1024; // 1MB en bytes

    if (file) {
      if (file.size > maxSize) {
        const errorMessage = `La imagen ${file.name} supera el tamaño máximo de 1MB.`;
        setLocalError(errorMessage);
        setError("images", { type: "manual", message: errorMessage });
        return; // No continuar si el archivo es demasiado grande
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const newURL = e.target.result;
        if (validPhotoURLs.length < 4) {
          const newPhotoURLs = [...validPhotoURLs, newURL]; // Añadir la URL directamente
          onChange(newPhotoURLs);
        }
        setLocalError(""); // Limpiar error si la carga es exitosa
        setError("images", { type: "manual", message: "" }); // Limpiar error en el formulario principal
        event.target.value = ""; // Restablecer el valor del input para permitir recargar la misma imagen si se elimina
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemovePhoto = (index) => {
    const newPhotoURLs = validPhotoURLs.filter((_, i) => i !== index);
    onChange(newPhotoURLs);
    setLocalError(""); // Limpiar cualquier error al eliminar una foto
    setError("images", { type: "manual", message: "" }); // Limpiar error en el formulario principal
  };

  const renderPhotoItem = (url, index) => (
    <React.Fragment key={index}>
      <div className="flex items-center justify-between mb-2 bg-gray-100 rounded-lg p-3">
        <Box display="flex" alignItems="center">
          <img
            src={url}
            alt={`Foto ${index + 1}`}
            style={{
              width: "60px",
              height: "60px",
              objectFit: "cover",
              borderRadius: "8px",
              marginRight: "10px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          />
          <Typography variant="body2">{`Foto ${index + 1}`}</Typography>
        </Box>
        <IconButton
          onClick={() => handleRemovePhoto(index)}
          className="hover:bg-gray-200 rounded-full transition-colors"
        >
          <DeleteIcon className="text-gray-500" />
        </IconButton>
      </div>
    </React.Fragment>
  );

  return (
    <Container
      style={{
        minHeight: "200px",
        width: "90%",
        padding: "16px",
      }}
    >
      <Box>
        <Typography variant="h6" gutterBottom>
          Subir Fotos
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          fontSize={13}
          marginBottom={2}
          gutterBottom
        >
          Toma las fotos 📷 de tu producto con buena iluminación, con luz de sol
          y fondo de un solo color. Formatos permitidos: JPG y PNG.
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          fontSize={13}
          marginBottom={2}
          gutterBottom
        >
          Añade entre 1 a 4 fotos. Tamaño máximo por foto: 2MB.
        </Typography>
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="raised-button-file"
          type="file"
          onChange={handleAddPhoto}
          disabled={validPhotoURLs.length >= 4}
        />
        {(error || localError) && (
          <Typography
            variant="body2"
            color="error"
            style={{ marginTop: "16px" }}
          >
            {error || localError}
          </Typography>
        )}
        <Box mt={2} width="100%">
          {validPhotoURLs.map((url, index) => renderPhotoItem(url, index))}
        </Box>
      </Box>
      {validPhotoURLs.length < 4 && (
          <label htmlFor="raised-button-file">
            <Button
              component="span"
              startIcon={<AddAPhotoIcon />}
              disabled={validPhotoURLs.length >= 4}
              style={{ color: "#384b2e", padding: "8px"}}
              sx={{
                '&:hover': {
                  backgroundColor: '#e7ffe7', // Color de hover personalizado
                },
              }}
            >
              {validPhotoURLs.length > 0 ? "Agregar más" : "Subir foto"}
            </Button>
          </label>
        )}
    </Container>
  );
};

export default SubirFotos;
