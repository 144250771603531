import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router,Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import CssBaseline from '@mui/material/CssBaseline';
import './styles.css';
import WhatsAppStepperForm2 from './componentes/stepper';
import TestPost from './useFetch';
import TestPost_departamentos from './APIS/departamentos';
import TestPost_categorias from './APIS/categorias';
import Test_frecuencia from './APIS/frecuencia_venta';
import Test_frecuencia_pago from './APIS/frecuencia_pago';
import CircularProgress from '@mui/material/CircularProgress';
import ReportIcon from '@mui/icons-material/Report';


function App() {
  const location = useLocation();

  const getTokenFromUrl = () => {
    const params = new URLSearchParams(location.search);
    return params.get('token');
  };

  const token = getTokenFromUrl();
  // console.log('first token:', token);

  //VARIABLES PARA GUARDAR LAS URL DE LAS APIS
  const urlbase_Prod = "https://h1bs6ha6xe.execute-api.us-east-2.amazonaws.com/stage";
  const urlbase_Test = "https://gv7ws4bxwa.execute-api.us-east-2.amazonaws.com/stage";
  const urlbase_token = "/verificar_token_edicion?token=";
  const urlbase_departamentos = "/obtenerDepartamentos";
  const urlbase_categorias = "/obtenerCategorias";
  const urlbase_frecuencia = "/obtenerFrecuencias";
  const urlbase_frecuencia_pago = "/obtenerFrecuenciasPago";
  // Estado para almacenar los datos de la API
  const [apiData, setApiData] = useState(null); 
  const [ulrbase, setUrlBase] = useState(urlbase_Prod);
  const [apiUrl, setApiUrl] = useState(`${urlbase_Prod}${urlbase_token}${token}`);
  const [apidepartamentos, setApiDepartamentos] = useState(`${urlbase_Prod}${urlbase_departamentos}`);
  const [apicategorias, setApiCategorias] = useState(`${urlbase_Prod}${urlbase_categorias}`);
  const [apifrecuencia, setApiFrecuencia] = useState(`${urlbase_Prod}${urlbase_frecuencia}`);
  const [apifrecuencia_pago, setApiFrecuencia_pago] = useState(`${urlbase_Prod}${urlbase_frecuencia_pago}`);

  const { data, loading, error } = TestPost(apiUrl);
  const[departamentos, setDepartamentos] = useState(null);
  const { data: dataDepartamentos, loading: loadingDepartamentos, error: errorDepartamentos } = TestPost_departamentos(apidepartamentos);

  const[categorias, setCategorias] = useState(null);
  const { data: dataCategorias, loading: loadingCategorias, error: errorCategorias } = TestPost_categorias(apicategorias);
  const [frecuencia, setFrecuencia] = useState(null);
  const { data: dataFrecuencia, loading: loadingFrecuencia, error: errorFrecuencia } = Test_frecuencia(apifrecuencia);

  const [frecuencia_pago, setFrecuencia_pago] = useState(null);
  const { data: dataFrecuencia_pago, loading: loadingFrecuencia_pago, error: errorFrecuencia_pago } = Test_frecuencia_pago(apifrecuencia_pago);

  const handleApiError = (error, setApiUrl, urlbase_Test, urlbase) => {
    if (error) {
      setApiUrl(`${urlbase_Test}${urlbase}`);
      setUrlBase(urlbase_Test);
    }
  };
  // API DATA - USUARIO
  useEffect(() => {
    if (data) {
      if (data.cliente && data.producto) {
        setApiData(data);
        // console.log('Data:', data);
      } else {
        // console.error("Unexpected data structure:", data);
      }
    }
    handleApiError(error, setApiUrl, urlbase_Test, urlbase_token + token);
  }, [data, error, token]);

  // API DATA - DEPARTAMENTOS
  useEffect(() => {
    if (departamentos) {
      setDepartamentos(departamentos);
    }
    handleApiError(errorDepartamentos, setApiDepartamentos, urlbase_Test, urlbase_departamentos);
  }, [departamentos, errorDepartamentos]);

  // API DATA - CATEGORIAS
  useEffect(() => {
    if (categorias) {
      setCategorias(categorias);
    }
    handleApiError(errorCategorias, setApiCategorias, urlbase_Test, urlbase_categorias);
  }, [categorias, errorCategorias]);

  // API DATA - FRECUENCIA DE VENTA
  useEffect(() => {
    if (frecuencia) {
      setFrecuencia(frecuencia);
    }
    handleApiError(errorFrecuencia, setApiFrecuencia, urlbase_Test, urlbase_frecuencia);
  }, [frecuencia, errorFrecuencia]);

  //API DATA - FRECUENCIA DE PAGO
  useEffect(() => {
    if (frecuencia_pago) {
      setFrecuencia_pago(frecuencia_pago);
    }
    handleApiError(errorFrecuencia_pago, setApiFrecuencia_pago, urlbase_Test, urlbase_frecuencia_pago);
  }, [frecuencia_pago, errorFrecuencia_pago]);

  // Debugging para ver el estado actual de los datos
  useEffect(() => {
    if (apiData) {
      // console.log('Current apiData:', apiData);
    }
  }, [apiData]);

  useEffect(() => {
    if (dataDepartamentos) {
      // console.log('Current departamentosData:', dataDepartamentos);
    }
  }, [dataDepartamentos]);

  // useEffect(() => {
  //   if (dataCategorias) {
  //     console.log('Categorias API:', dataCategorias);
  //   }
  // }, [dataCategorias]);

  // useEffect(() => {
  //   if (dataFrecuencia) {
  //     console.log('Frecuencia API:', dataFrecuencia);
  //   }
  // }, [dataFrecuencia]);

  // useEffect(() => {
  //   if (dataFrecuencia_pago) {
  //     console.log('Frecuencia de pago API:', dataFrecuencia_pago);
  //   }
  // }, [dataFrecuencia_pago]);

  return (
    <>
      <CssBaseline />
      <div className="App">
        <Routes>
          <Route path="/" element={
            <div style={{display:'flex',justifyContent: 'center', alignItems:'center', flexDirection:'column', height:'100vh'}}>
            <ReportIcon style={{fontSize: 100, color: 'red'}}/>
            <div style={{width:'200px'}}>¡Pagina web no disponible!</div>
              
            </div>
          } />
          <Route path="/editar-producto" element={
            loading || loadingDepartamentos || loadingCategorias || loadingFrecuencia || loadingFrecuencia_pago ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress sx={{color:"black"}}/>
              </div>
            ) : (
              apiData && dataDepartamentos && dataCategorias && dataFrecuencia && dataFrecuencia_pago ? (
                <WhatsAppStepperForm2 token={token} apiData={apiData} dataDepartamentos={dataDepartamentos} dataCategorias={dataCategorias} dataFrecuencia={dataFrecuencia} dataFrecuencia_pago={dataFrecuencia_pago} ulrbase={ulrbase}/>
              ) : (
                <div style={{display:'flex',justifyContent: 'center', alignItems:'center', flexDirection:'column', height:'100vh'}}>
                  {/* <ReportIcon style={{fontSize: 100, color: 'yellow'}}/> */}
                  <img src='/imagenes/Icon_alert.svg' alt='icono alerta' width={'100px'}/>
                  <div style={{width:'200px'}}>¡Producto no disponible para editar!</div>
                </div>
              )
            )
          } />
        </Routes>
      </div>
    </>
  );
}

export default App;
