import { useState, useEffect } from "react";

export default function Test_frecuencia(url) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
            
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();  // Aquí se recibe el cuerpo de la respuesta
        })
        .then((data) => setData(data))  // Guarda los datos recibidos
        .catch((error) => setError(error))  // Manejo de errores
        .finally(() => setLoading(false));  
    }, [url]);

    return { data, loading, error };  // Devuelve el estado, los datos y los errores
}